<template>
  <div class="info" v-if="props.data">
     
    <!-- <BrandDesc
      :info="props.data.description"
      :nm="props.data.name"
      :nmalt="props.data.name"      
      :web="props.data.web"
      :brand_package="props.data.brand_package"
      :items_photo="props.data.items_photo"
      /> -->

    <BrandProductListItems
      :title="'Strains'"
      :showmore="$t('breeder_view_show_all_strains')"      
      :link="$route.path + '/strains'"
      :inurl="'/brands-seed/' + props.data.id + '/strains'"
      :brand-id="props.data.id"
      :intags="$tagsUtil.tagsBrandSeedProducts"
      />    
          

    <BrandDiaries v-if="props.data.items_diary && props.data.items_diary.length"
      :title="$t('breeder_tab_info_awards')"
      :showmore="$t('breeder_view_show_all_diaries')"      
      :list="props.data.items_diary.filter(b => {return b.group_name === 'win'})"
      :cnt="props.data.item_stat.cnt_reports"
      :link="$route.path + '/diaries'"
      />   
     
      <div class="cpm"></div>
     
<!--      
    <BrandProductItems
      :title="'2021 ' + $t('strain_view_release')"
      :showmore="$t('breeder_view_show_all_strains')"      
      :products="productsCurrentYear"
      :link="$route.path + '/strains'"
      />    
          
    <BrandProductItems
      :title="'2020 ' + $t('strain_view_release')"
      :showmore="$t('breeder_view_show_all_strains')"      
      :products="productsYearAgo"
      :link="$route.path + '/strains'"
      />    
     
    <BrandProductItems
      :title="$t('strain_view_previously_released')"
      :showmore="$t('breeder_view_show_all_strains')"      
      :products="productsOld"
      :link="$route.path + '/strains'"
      />    
      -->

    <BrandDiaries v-if="props.data.items_diary && props.data.items_diary.length"
      :title="$t('breeder_view_subtitle_top_diaries')"
      :showmore="$t('breeder_view_show_all_diaries')"      
      :list="props.data.items_diary.filter(b => {return b.group_name === 'top'})"
      :cnt="props.data.item_stat.cnt_reports"
      :link="$route.path + '/diaries'"
      />   
     
    <BrandDiaries v-if="props.data.items_diary && props.data.items_diary.length"
      :title="$t('breeder_view_subtitle_new_diaries')"
      :showmore="$t('breeder_view_show_all_diaries')"      
      :list="props.data.items_diary.filter(b => {return b.group_name === 'new'})"
      :cnt="props.data.item_stat.cnt_reports"
      :link="$route.path + '/diaries'"
      />


    <BrandShorts  
      :title="'Shorts'"
      :showmore="'Show all'"
      :tag="'bsd:' + props.data.id + ':' + props.data.section"
      />
     
     
    <BrandReviewItems
      :title="$t('breeder_view_latest_reviews')"
      :showmore="$t('breeder_view_show_all_reviews')"    
      :link="$route.path + '/reviews'"    
      :list="props.data.items_review"
      :cnt="props.data.item_stat.cnt_reviews"
      />    
      
    <div class="cpm"></div>
      
    <BrandHarvestItems
      :title="$t('breeder_view_show_all_harvests')"
      :showmore="$t('breeder_view_show_all_harvests')"     
      :link="$route.path + '/harvests'" 
      :list="props.data.items_harvest"
      :cnt="props.data.item_stat.cnt_harvests"
      />    
     

    <BrandQuestionItems
      :title="$t('breeder_view_info_grow_questions')"
      :showmore="$t('breeder_view_info_grow_questions_all')"     
      :link="'/grow-questions?q_breeder=' + props.data.id"    
      :list="props.data.items_problem"
      :cnt="props.data.item_stat.cnt_problem"
      /> 
      
    <div class="cpm"></div>
     
     
    <BrandSimilarBrands
      :title="$t('breeder_view_info_sidebar_title')"   
      :list="props.data.items_similar_brand"  
      />

    <CommentWidget v-if="props.data && props.data.id"
      :title="$t('breeder_view_subtitle_latest_comments')"
      :type="'brand'"
      :content="props.data.id"
      />


  </div>
</template>

<script setup>



const props = defineProps({
  data: {
    type: Object
  },
});

const productsCurrentYear = computed(() => {
  return getProductsList(new Date().getFullYear());
});

const productsYearAgo = computed(() => {
  return getProductsList(new Date().getFullYear()-1);
});

const productsOld = computed(() => {
  return getProductsList('old');
});

const getProductsList = function(year){      
  var arr = [];      
  if(props.data.items)
    for(var item of props.data.items)
      if(year == item.group_name)
        arr.push(item);        
  return arr;
};


</script>

<style scoped>


.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}
.flow_boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

</style>
